export const DEFAULT_LOCALE = 'en';

export const INTL_LOCALE_OVERRIDES = {
  no: 'nb-NO',
};

export const AVAILABLE_LOCALES = process.env.AVAILABLE_LOCALES || [
  DEFAULT_LOCALE,
];

let _publicPath = process.env.PUBLIC_PATH;

export const SET_PUBLIC_PATH = pubPath => {
  _publicPath = pubPath;
};

export const GET_PUBLIC_PATH = () => _publicPath;
