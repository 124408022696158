import {
  getNumberOfRows,
  getVideosInRowCount,
  isGridLayout,
  isStripLayout,
} from 'shared/selectors/app-settings';

export const getVideosCount = ({ isMobile, styleParams }) => {
  if (isMobile) {
    return;
  }

  const state = { appSettings: styleParams };

  if (isGridLayout(state) || isStripLayout(state)) {
    return getNumberOfRows(state) * getVideosInRowCount(state);
  }
};
