import { noop } from 'lodash';
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';

const MOBILE_OVERLAY_ENTRY = 'mobile-overlay.html';
const FULL_SCREEN_OVERLAY_ENTRY = 'full-screen-modals.html';

export function createOpenOverlayHandlers({ wixCodeApi, compId, appParams }) {
  let { staticsBaseUrl } = appParams.baseUrls;

  if (!staticsBaseUrl.endsWith('/')) {
    staticsBaseUrl = staticsBaseUrl + '/';
  }

  const getRootCompId = () => `rootCompId=${compId}`;
  const getRootPageId = () => `rootPageId=${wixCodeApi.site.currentPage.id}`;

  const { scrollTo, viewMode } = wixCodeApi.window;

  async function openPopup(path, width, height, theme, onClose = noop) {
    const { scroll } = await wixCodeApi.window.getBoundingRect();

    const result = await wixCodeApi.window.openPopup(
      path,
      {
        compId,
        width,
        height,
        position: {
          origin: 'FIXED',
          placement: 'CENTER',
        },
        theme,
      },
      compId,
    );

    onClose(result && result.message && result.message.reason);
    scrollTo(scroll.x, scroll.y);
  }

  const openOverlay = entry => async (subPath = '', onClose = noop) => {
    const modalPath = `${staticsBaseUrl}${entry}?${getRootCompId()}&${getRootPageId()}${subPath}`;
    await openPopup(modalPath, '100%', '100%', 'BARE', onClose);
  };

  return {
    openMobileOverlay: (subPath, onClose) => {
      if (viewMode === VIEW_MODES.PREVIEW) {
        scrollTo(0, 0);
      }

      openOverlay(MOBILE_OVERLAY_ENTRY)(subPath, onClose);
    },
    openFullScreenOverlay: openOverlay(FULL_SCREEN_OVERLAY_ENTRY),
  };
}
