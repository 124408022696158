import _ from 'lodash';
import { DEFAULT_LOCALE } from './config';
import parseQueryParams from 'shared/utils/get-query-params';

// Language priority (high to low):
// 1) Multilingual ('lang ') query param
// 2) Widget App Settings ('fonts.language')
// 3) Site language
// 4) Default locale
export const getAppLocale = ({
  appSettings,
  isRTLExperimentEnabled,
  siteLocale,
}) => {
  const queryParams =
    typeof window === 'undefined' ? {} : parseQueryParams(window.location.href);
  const appSettingsLocale = _.get(appSettings, 'fonts.language');

  if (!isRTLExperimentEnabled) {
    return appSettingsLocale || siteLocale || DEFAULT_LOCALE;
  }

  return queryParams.lang || appSettingsLocale || siteLocale || DEFAULT_LOCALE;
};
