import { noop } from 'lodash';
import { createWorkerHandler } from './createHandler';
import { createFedopsHandler } from './handlers/fedopsLogger';
import { createPerformanceHandler } from './handlers/performanceLogger';

export const createViewerScriptConfigForOOI = {
  createWorkerHandlerFromConfig(
    controllerConfig,
    { performanceLogger, isSlave },
  ) {
    const { compId, platformAPIs, wixCodeApi } = controllerConfig;
    const { pubSub } = platformAPIs;

    const loggerFactory = name =>
      new platformAPIs.fedOpsLoggerFactory.constructor(name);

    const fedopsHandler = createFedopsHandler({
      loggerFactory,
      pubSub,
      compId,
      baseLoggerName: 'wix-vod-widget',
      isSlave,
    });

    const setNewDimensions = (width, height) =>
      controllerConfig.setProps({
        dimensions: {
          width,
          height,
        },
      });

    return createWorkerHandler(controllerConfig, {
      performanceApplicationLoadedHandler: createPerformanceHandler({
        performanceLogger,
      }),
      fedopsHandler,
      handleSetWidgetHeight: (height, width) => setNewDimensions(width, height),
      handleFitIntoView: noop,
      handleResizeWindow: setNewDimensions,
      handleResizeComponent: setNewDimensions,
      handleLogin: async ({ mode, language }, onLogin, onError) => {
        try {
          const user = await wixCodeApi.user.promptLogin({ mode, language });

          onLogin({
            id: user.id,
            email: await user.getEmail(),
          });
        } catch (error) {
          onError(error);
        }
      },
      handleLogout: wixCodeApi.user.logout,
    });
  },
};
