import { handleActions } from 'redux-actions';
import { NAMES } from 'mobile/redux/actions/modules';

const defaultState = {
  playbackModuleLoaded: false,
};

export default handleActions(
  {
    [NAMES.PLAYBACK_MODULE_LOADED]: state => ({
      ...state,
      playbackModuleLoaded: true,
    }),
  },
  defaultState,
);
