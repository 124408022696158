import { handleActions } from 'redux-actions';
import { NAMES } from 'mobile/redux/actions/slide-menu';

export default handleActions(
  {
    [NAMES.OPEN_SLIDE_MENU]: () => true,
    [NAMES.CLOSE_SLIDE_MENU]: () => false,
  },
  false,
);
