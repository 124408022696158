const getEmail = async currentUser => {
  try {
    return await currentUser.getEmail();
  } catch (e) {
    if (global.Wix && global.Wix.currentMember) {
      /*
       *  TODO: wixCodeApi.user.currentUser.getEmail() is buggy;
       *  and throws an error if wixCodeApp isn't installed for a user
       *  that said this fallback allows to get user email at least in fs-modals/mobile overlays
       * */
      return (await new Promise(global.Wix.currentMember)).email;
    }
    return ' ';
  }
};

export const getCurrentSiteUser = async currentUser => {
  if (currentUser && currentUser.loggedIn) {
    return {
      id: currentUser.id,
      email: await getEmail(),
    };
  }

  return null;
};
