import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
  GET_PUBLIC_PATH,
} from 'services/locale/config';

export const fetchTranslations = async locale => {
  const localeToFetch = AVAILABLE_LOCALES.includes(locale)
    ? locale
    : DEFAULT_LOCALE;
  const response = await fetch(
    `${GET_PUBLIC_PATH()}static/locales/${localeToFetch}.json`,
  );
  return await response.json();
};
