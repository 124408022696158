import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import slideMenuOpened from 'mobile/redux/reducers/slide-menu-opened';
import modules from 'mobile/redux/reducers/modules';

import videos from 'widget/redux/client/reducers/videos';
import channels from 'widget/redux/client/reducers/channels';
import channelVideos from 'widget/redux/client/reducers/channel-videos';

import configuration from 'widget/redux/client/reducers/configuration';
import appSettings from 'widget/redux/client/reducers/app-settings';
import currentChannelId from 'widget/redux/client/reducers/current-channel-id';
import search from 'widget/redux/client/reducers/search';
import channelInfo from 'widget/redux/client/reducers/channel-info';
import firstChannelVideoId from 'widget/redux/client/reducers/first-channel-video-id';
import shareOverlay from 'widget/redux/client/reducers/share-overlay';
import videoUrlsCache from 'widget/redux/client/reducers/video-urls-cache';
import selectedVideoId from 'widget/redux/client/reducers/selected-video-id';
import player from 'widget/redux/client/reducers/player';
import isVideosTouched from 'widget/redux/client/reducers/is-videos-touched';
import experiments from 'shared/redux/client/reducers/experiments';
import vodSettings from 'shared/redux/client/reducers/vod-settings';
import playback from 'shared/redux/client/reducers/playback';
import videoCards from 'widget/redux/client/reducers/video-cards/video-cards';
import payment from 'widget/redux/client/reducers/payment';
import lazyChannelVideos from 'widget/redux/client/lazy-channel-videos/reducer';
import windowSize from 'widget/redux/client/reducers/window-size';
import isLightbox from 'widget/redux/client/reducers/is-lightbox';
import fullScreenModal from 'widget/redux/client/reducers/full-screen-modal';
import comments from 'widget/redux/client/reducers/comments';
import { reducer as hydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';

const createWidgetReducers = ({ history }) => ({
  router: connectRouter(history),

  slideMenuOpened,
  modules,

  search,
  entities: combineReducers({
    videos,
    channels,
  }),
  channelVideos,
  currentChannelId,
  channelInfo,
  firstChannelVideoId,

  lazyChannelVideos,
  windowSize,
  isLightbox,

  videoUrlsCache,
  selectedVideoId,
  player,
  isVideosTouched,
  payment,

  configuration,
  appSettings,
  experiments,
  vodSettings,
  videoCards,
  shareOverlay,

  fullScreenModal,
  comments,
  hydratedData,
  playback,
});

export default ({ history }) =>
  combineReducers(createWidgetReducers({ history }));
