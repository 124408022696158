import {
  getChannelIdFromAppSettings,
  isVerticalLayoutMobile,
} from 'shared/selectors/app-settings';
import { getVideoIds } from 'widget/redux/client/lazy-channel-videos/selectors';
import { clearHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import { getPublic as getChannel } from 'widget/redux/server/actions/channel/get-public';
import { getPaymentInfo } from 'widget/redux/server/actions/channel/payment';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getVideoId } from 'widget/utils/app-settings';
import { getExperiments } from 'shared/redux/server/actions/experiments/get';
import {
  loadMoreVideoPages,
  prependVideoIds,
} from 'widget/redux/client/lazy-channel-videos/actions';
import { getPublicVideo } from 'widget/redux/server/actions/channel/video/get';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import _ from 'lodash';
import { getVideoIdFromUrl } from 'widget/selectors/query-params';
import { getVideoById } from 'shared/selectors/videos';
import { openVideoPage } from 'widget/redux/client/actions/mobile-overlay-openners/main-openners';

export const setInitialState = () => async (dispatch, getState) => {
  const state = getState();
  const channelId = getChannelIdFromAppSettings(state);
  const singleVideoId = getVideoId(state.appSettings);

  const videoIdFromUrl = getVideoIdFromUrl(state);

  await Promise.all([
    dispatch(getChannel(channelId)),
    dispatch(getExperiments()),
    dispatch(getPaymentInfo(channelId)),
  ]);

  if (singleVideoId) {
    await dispatch(getPublicVideo(channelId, singleVideoId));
    dispatch(prependVideoIds(singleVideoId));
    dispatch(selectVideo(singleVideoId));
  } else {
    const pagesCount = isVerticalLayoutMobile(state) ? 2 : undefined;
    await dispatch(loadMoreVideoPages(pagesCount));

    const videoIds = getVideoIds(getState());
    const mainVideoId = getMainVideoId(getState());

    if (!_.includes(videoIds, mainVideoId)) {
      dispatch(prependVideoIds(mainVideoId));
    }
  }

  if (videoIdFromUrl) {
    await getPublicVideo(channelId, videoIdFromUrl);
  }

  dispatch(clearHydratedData());
};

export const openVideoFromUrlIfExists = () => (dispatch, getState) => {
  const state = getState();
  const videoIdFromUrl = getVideoIdFromUrl(state);
  const videoFromUrlExists = getVideoById(state, videoIdFromUrl);
  if (videoFromUrlExists) {
    dispatch(openVideoPage(videoIdFromUrl));
  }
};
