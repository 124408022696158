import Chain from './chain';

export default () => [
  new Chain(
    'widget.vid.clicked',
    'player-play-requested',
    'player-play-done',
    'widget.vid.start.play',
    'player.debug.info',
    'widget.vid.played',
    'widget.vid.fullScreen.clicked',
    'widget.vid.error',
    'player-error',
    'widget.vid.fullScreen.entered',
  ),
];
