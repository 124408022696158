import { logBi } from './actions/bi';
import { pubSubPublish } from './actions/pub-sub/publish';
import { createLogBiHandler } from './handlers/bi';
import { createPubSubPublishHandler } from './handlers/pub-sub/publish';
import { createHandler } from './lib';
import { logFedopsAction } from './actions/fedopsLogger';
import {
  openFullScreenOverlay,
  openMobileOverlay,
} from './actions/openOverlay';
import { createVodBiLogger } from 'shared/bi/create-bi';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import { createSetWidgetHeightHandler } from 'shared/worker/handlers/resize/set-widget-height';
import { resizeWindow } from 'shared/worker/actions/resize/resize-window';
import { createResizeWindowHandler } from 'shared/worker/handlers/resize/resize-window';
import { resizeComponent } from 'shared/worker/actions/resize/resize-component';
import { createResizeComponentHandler } from 'shared/worker/handlers/resize/resize-component';
import { createOpenOverlayHandlers } from './handlers/openOverlay';
import { performanceApplicationLoaded } from './actions/performanceLogger';
import { fitIntoView } from 'shared/worker/actions/fit-into-view';
import { createFitIntoViewHandler } from 'shared/worker/handlers/fit-into-view';
import { requestLogin } from 'shared/worker/actions/user/request-login';
import { createRequestLoginHandler } from 'shared/worker/handlers/user/login';
import { logout } from 'shared/worker/actions/user/logout';
import { createLogoutHandler } from 'shared/worker/handlers/user/logout';
import { getBiToken } from 'shared/worker/getBiToken';

function createBi({ platformAPIs, appParams, compId }) {
  const factory = args => {
    const res = platformAPIs.biLoggerFactory(args);
    if (!res.initialized) {
      res.initFactory();
    }
    return res.loggerClientFactory;
  };
  const { biToken, visitorId } = platformAPIs.bi;
  const { instanceId, instance } = appParams;

  return createVodBiLogger(factory, {
    biToken: biToken || getBiToken(instance, platformAPIs),
    videoInstanceID: instanceId,
    compId,
    visitorID: visitorId, //TODO ID or Id?
  });
}

export function createWorkerHandler(
  controllerConfig,
  {
    fedopsHandler,
    handleSetWidgetHeight,
    handleResizeWindow,
    handleResizeComponent,
    performanceApplicationLoadedHandler,
    handleFitIntoView,
    handleLogin,
    handleLogout,
  },
) {
  const { platformAPIs } = controllerConfig;
  const pubSub = platformAPIs.pubSub;
  const bi = createBi(controllerConfig);

  const overlayHandlers = createOpenOverlayHandlers(controllerConfig);

  return createHandler([
    [logBi, createLogBiHandler(bi)],
    [pubSubPublish, createPubSubPublishHandler(pubSub)],
    [logFedopsAction, fedopsHandler],
    [setWidgetHeight, createSetWidgetHeightHandler(handleSetWidgetHeight)],
    [resizeWindow, createResizeWindowHandler(handleResizeWindow)],
    [resizeComponent, createResizeComponentHandler(handleResizeComponent)],
    [openFullScreenOverlay, overlayHandlers.openFullScreenOverlay],
    [openMobileOverlay, overlayHandlers.openMobileOverlay],
    [performanceApplicationLoaded, performanceApplicationLoadedHandler],
    [fitIntoView, createFitIntoViewHandler(handleFitIntoView)],
    [requestLogin, createRequestLoginHandler(handleLogin)],
    [logout, createLogoutHandler(handleLogout)],
  ]);
}
