import DEVICE_TYPES from 'shared/constants/device-types';
import { getBiToken } from 'shared/worker/getBiToken';

const getDsn = deviceType => {
  return deviceType === DEVICE_TYPES.MOBILE
    ? 'https://55249fbac04c4eec85f716ce6ecd8007@sentry.wixpress.com/72'
    : 'https://31123ec476ce4702988dd34b68d01b9e@sentry.wixpress.com/68';
};

function getParams({ wixCodeApi, platformAPIs, appParams, compId }) {
  const deviceType = wixCodeApi.window.formFactor.toLowerCase();
  const biToken = getBiToken(appParams.instance, platformAPIs);
  const metaSiteId = platformAPIs.bi.metaSiteId;
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();
  const { instanceId } = appParams;

  const isLoggedIn =
    wixCodeApi.user.currentUser && wixCodeApi.user.currentUser.loggedIn;

  const userId = isLoggedIn ? wixCodeApi.user.currentUser.id : null;
  const renderingEnv = wixCodeApi.window.rendering.env;

  return {
    deviceType,
    biToken,
    metaSiteId,
    viewMode,
    instanceId,
    compId,
    userId,
    renderingEnv,
  };
}

export function createAndSetupMonitor(createMonitor, controllerConfig) {
  const params = getParams(controllerConfig);
  const monitor = createMonitor(getDsn(params.deviceType));

  monitor.setEnvironment(__DEBUG__ ? 'development' : 'production');
  monitor.setRelease(__CI_APP_VERSION__);
  monitor.setUser({
    id: params.userId,
  });

  monitor.setTagsContext({
    ooif: __OOI__,
    deviceType: params.deviceType,
    viewMode: params.viewMode,
    renderingEnv: params.renderingEnv,
  });

  monitor.setExtraContext({
    biToken: params.biToken,
    instanceId: params.instanceId,
    msid: params.metaSiteId,
    compId: params.compId,
  });

  return monitor;
}
