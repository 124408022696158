import _ from 'lodash';
/* global ENV_CONFIG */
import { BI_SOURCES } from '@wix/wix-vod-constants/dist/bi/sources';
import events from 'shared/bi/events';

import getCorrelationChains from 'shared/bi/correlation/chains';
import Chain from 'shared/bi/correlation/chain';
import { logBI } from '@wix/wix-vod-shared/dist/src/common/logger';

export function createVodBiLogger(factory, defaultParams = {}) {
  const knownEvents = _.mapValues(events, evid => ({ evid }));

  const biFactory = factory({ endpoint: 'video' })
    .updateDefaults({
      src: BI_SOURCES.VOD_SRC_ID,
      ...defaultParams,
    })
    .setEvents(knownEvents);

  if (ENV_CONFIG.disableBi) {
    biFactory._publishers.splice(0, 1);
  }

  if (ENV_CONFIG.logBiSend) {
    biFactory.addPublisher(event => {
      const eventName = _.findKey(knownEvents, { evid: event.evid });
      logBI({ eventName, event });
    });
  }

  const logger = biFactory.logger({ endpoint: 'video' });

  return withCorrelation(logger);
}

const withCorrelation = biLoggerInstance => {
  const correlationChains = getCorrelationChains();
  const originalLog = biLoggerInstance.log.bind(biLoggerInstance);

  biLoggerInstance.log = (eventName, params) => {
    const correlationId = _(correlationChains)
      .invokeMap(Chain.prototype.getCorrelationId, eventName)
      .compact()
      .head();

    originalLog(eventName, {
      ...params,
      ...(correlationId && { correlationId }),
    });
  };

  return biLoggerInstance;
};
